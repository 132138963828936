import * as React from "react";
import { useMemo } from "react";
import { JavascriptDays } from "../../../util/enpoints/types";

type Props = {
  start: number;
  finish: number;
  day?: string;
};
const InBetweenLine: React.FC<Props> = ({ start, finish, day }) => {
  const isActive = useMemo(() => {
    if (day === JavascriptDays[new Date().getDay()] || day == null) {
      const currentSeconds = new Date().getHours() * 3600 + new Date().getMinutes() * 60 + new Date().getSeconds();
      return start < currentSeconds && currentSeconds < finish;
    }
    return false;
  }, [day, start, finish]);
  if (!isActive) return null;
  return (
    <div
      className="w-full rounded-full h-0.5"
      style={{
        background:
          "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 90%, rgba(255,255,255,0) 100%)",
      }}
    />
  );
};

export default InBetweenLine;
