import * as _ from "lodash";
import { Days, TClassSimple, TimetableSimple } from "./enpoints/types";

export function getClassesFromTable<T extends TClassSimple>(t: TimetableSimple<T>): T[] {
  if (t.weeks === undefined || t.weeks.length === 0) {
    return new Array<T>();
  }
  let res = new Array<T>();
  for (const week of t.weeks) {
    for (const day of Object.values(week)) {
      res = res.concat(day);
    }
  }
  return res;
}

export function sortClass<T extends TClassSimple>(classes: T[]): T[] {
  return _.sortBy(classes, [(c) => c.week, (c) => Days.indexOf(c.day), (c) => c.start, (c) => c.duration]);
}

export function equalsClass(first: TClassSimple, second: TClassSimple): boolean {
  return (
    first.day === second.day &&
    first.week === second.week &&
    first.start === second.start &&
    first.duration === second.duration &&
    first.subject === second.subject &&
    first.type === second.type &&
    first.teacher === second.teacher
  );
}

export function insertClassInTable(
  table: TimetableSimple<TClassSimple>,
  c: TClassSimple
): TimetableSimple<TClassSimple> {
  while (table.weeks.length <= c.week) {
    table.weeks.push({
      monday: new Array<TClassSimple>(),
      tuesday: new Array<TClassSimple>(),
      wednesday: new Array<TClassSimple>(),
      thursday: new Array<TClassSimple>(),
      friday: new Array<TClassSimple>(),
      saturday: new Array<TClassSimple>(),
      sunday: new Array<TClassSimple>(),
    });
  }
  const d = table.weeks[c.week][c.day];
  for (const cl of d) {
    if (equalsClass(cl, c)) {
      throw new EvalError("classAlreadyInTimetable");
    }
  }
  table.weeks[c.week][c.day].push(c);
  return table;
}
