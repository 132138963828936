import * as React from "react";
import { useMemo } from "react";
import { MdSchool } from "react-icons/md";
import { IoMdClock } from "react-icons/io";
import { Transition } from "@headlessui/react";
import { JavascriptDays, TClass } from "../../../util/enpoints/types";
import tw from "../../../util/tailwind-classnames";

type Props = {
  elem: TClass;
  externalTagName?: string; // name that external tags should contain - ex: Orar Personal/Personal Timetable
};
const TimetableClass: React.FC<Props> = ({ elem, externalTagName }) => {
  const currentSeconds = useMemo(
    () => new Date().getHours() * 3600 + new Date().getMinutes() * 60 + new Date().getSeconds(),
    [elem]
  );
  const isActive = useMemo(() => {
    if (elem.day === JavascriptDays[new Date().getDay()]) {
      return elem.start < currentSeconds && currentSeconds < elem.start + elem.duration;
    }
    return false;
  }, [currentSeconds, elem.day, elem.duration, elem.start]);
  const color = useMemo(() => {
    if (isActive) return "bg-twitter";
    if (elem.tagName === externalTagName) return "bg-orange-off";
    try {
      let [word] = elem.type.split(",");
      word = word.trim().toLowerCase();
      switch (word) {
        case "curs":
          return "bg-red";
        case "seminar":
          return "bg-green";
        case "laborator":
          return "bg-instagram";
        default:
          return "bg-orange";
      }
    } catch (e) {
      console.error(e);
    }
    return "bg-orange";
  }, [elem.tagName, elem.type, externalTagName, isActive]);
  const startTime = useMemo(() => new Date(elem.start * 1000).toISOString().substr(11, 5), [elem.start]);
  const finishTime = useMemo(
    () => new Date((elem.start + elem.duration) * 1000).toISOString().substr(11, 5),
    [elem.duration, elem.start]
  );
  // if the subject is the same as the tag hides the tag name to make the element visually smaller
  const sameTagAsSubject = useMemo(
    () => elem.tagName.toLowerCase().includes(elem.subject.toLowerCase()),
    [elem.subject, elem.tagName]
  );
  return (
    <Transition
      show
      appear
      enter="transition ease-out duration-300"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-150"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div className={`flex-col items-center rounded-2xl h-50 m-2 mx-4 ${color}`}>
        <div className="flex flex-row flex-nowrap justify-center items-center mx-4">
          <div
            className={`flex-shrink text-white text-2xl text-center text-bold pb-1.5 ${tw({
              "animate-bounce": isActive,
            })}`}
          >
            <MdSchool />
          </div>
          <p className="flex-grow text-white text-2xl md:text-3xl text-center text-bold">{elem.subject}</p>
          <div
            className={`flex-shrink text-white text-2xl text-center text-bold pb-1.5 opacity-0 ${tw({
              "animate-bounce": isActive,
            })}`}
          >
            <MdSchool />
          </div>
        </div>
        <div className="flex justify-center flex-col items-center">
          <p className="text-white text-lg text-center text-bold">{elem.teacher}</p>
        </div>
        {sameTagAsSubject ? null : (
          <div className="flex justify-center flex-col items-center">
            <p className="text-white text-lg text-center text-bold">{elem.type}</p>
          </div>
        )}
        <div className="flex justify-between items-center space-x-8 mx-4">
          <div className="flex justify-center items-center">
            <p
              className={`text-white text-2xl text-center text-bold pb-1.5 ${tw({
                "animate-spin": isActive,
              })}`}
            >
              <IoMdClock />
            </p>
            <p className="text-white text-md text-center text-bold ml-1">{startTime}</p>
          </div>
          <p className="text-white text-lg text-center text-bold">{sameTagAsSubject ? elem.type : elem.tagName}</p>
          <div className="flex justify-center items-center">
            <p className="text-white text-md text-center text-bold mr-1">{finishTime}</p>
            <p
              className={`text-white text-2xl text-center text-bold pb-1.5 ${tw({
                "animate-spin": isActive,
              })}`}
            >
              <IoMdClock />
            </p>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default TimetableClass;
