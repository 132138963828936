import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { Menu, Transition } from "@headlessui/react";
import { MdExpandMore, MdKeyboardArrowRight } from "react-icons/md";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { useTranslation } from "react-i18next";
import { BreadcrumbJsonLd } from "gatsby-plugin-next-seo";
import { useMemo } from "react";
import { tw } from "../util/tailwind-classnames";

type Item = {
  text: string;
  url: string | (() => any | Promise<any>);
};

type Props = {
  history: Item[];
  breadcrumbsSEO?: boolean;
};

const Breadcrumbs: React.FC<Props> = ({ history, breadcrumbsSEO = true }) => {
  const isMobile = useMediaQuery({ maxWidth: 800 });
  const { t } = useTranslation();
  const jsonld = useMemo(() => {
    if (breadcrumbsSEO) {
      return (
        <BreadcrumbJsonLd
          itemListElements={history.map((item, index) => ({
            position: index + 1,
            name: item.text,
            item: typeof item.url === "function" ? "" : item.url,
          }))}
        />
      );
    }
    return null;
  }, [breadcrumbsSEO, history]);

  if (history.length === 0) {
    return null;
  }

  if (isMobile)
    return (
      <>
        {jsonld}
        <Menu as="div" className="relative inline-block text-left z-9999">
          <div>
            <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
              {t("folderHierarchy")}
              <MdExpandMore className="w-5 h-5 ml-2 -mr-1" />
            </Menu.Button>
          </div>

          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute left-0 mt-2 w-56 rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {history.map((elem, index) => (
                  <Menu.Item key={index}>
                    {({ active }) =>
                      typeof elem.url === "function" ? (
                        <button
                          type="button"
                          onClick={elem.url}
                          className={tw(
                            active ? tw("bg-gray-100", "text-orange") : "text-black",
                            tw("block", "px-4", "py-2", "text-sm"),
                            "text-left"
                          )}
                        >
                          <span>{elem.text}</span>
                        </button>
                      ) : (
                        <Link
                          to={elem.url}
                          className={tw(
                            active ? tw("bg-gray-100", "text-orange") : "text-black",
                            tw("block", "px-4", "py-2", "text-sm"),
                            "text-left"
                          )}
                        >
                          <span>{elem.text}</span>
                        </Link>
                      )
                    }
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </>
    );

  return (
    <>
      {jsonld}
      <div className="p-2 gap-1 flex flex-wrap justify-start items-center rounded-lg text-white">
        {history.map((item, index, array) => (
          <>
            {typeof item.url === "function" ? (
              <button
                type="button"
                key={index.toString()}
                onClick={item.url}
                className="transition duration-500 transform hover:text-orange hover:scale-105 origin-center"
              >
                {item.text}
              </button>
            ) : (
              <Link
                type="button"
                key={index.toString()}
                to={item.url}
                className="transition duration-500 transform hover:text-orange hover:scale-105 origin-center"
              >
                {item.text}
              </Link>
            )}

            {index < array.length - 1 ? <MdKeyboardArrowRight className="h-5 w-5" /> : null}
          </>
        ))}
      </div>
    </>
  );
};

export default Breadcrumbs;
