import * as React from "react";
import { useTranslation } from "react-i18next";
import { Day, TClass } from "../../../util/enpoints/types";
import TimetableClass from "./class";
import { tw } from "../../../util/tailwind-classnames";
import InBetweenLine from "./InBetweenLine";

type Props = {
  day: Day;
  preview: boolean;
  data: TClass[];
  externalTagName: string;
  active?: boolean;
};
const TimetableDay: React.FC<Props> = ({ preview, day, data, externalTagName, active = false }) => {
  const { t } = useTranslation();
  const container = tw("flex-col", "w-full", "pt-6", {
    "sm:w-2/3": !preview,
    "md:w-1/2": !preview,
  });
  const dayTitleContainer = tw(
    "flex",
    "flex-col",
    "justify-center",
    "items-center",
    "border-b-2",
    "rounded-full",
    "mx-4",
    "mb-4",
    { "border-orange": !active, "border-twitter": active }
  );
  return (
    <div id={day} className={container}>
      <div className={dayTitleContainer}>
        <h1 className="text-4xl text-white mb-2">{t(day)}</h1>
      </div>
      <InBetweenLine start={0} finish={data.length > 0 ? data[0].start : 0} day={day} />
      {data.length === 0 ? (
        <p className="text-white text-3xl text-center mb-auto mt-auto">{t("noData")}</p>
      ) : (
        data.map((elem, index) => (
          <React.Fragment key={index.toString() + elem.uuid}>
            <TimetableClass elem={elem} externalTagName={externalTagName} />
            <InBetweenLine
              start={elem.start + elem.duration}
              finish={data.length > index + 1 ? data[index + 1].start : 86400}
              day={day}
            />
          </React.Fragment>
        ))
      )}
    </div>
  );
};

export default TimetableDay;
